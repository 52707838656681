import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionInfo } from '../../user/stripe/subscription';

export interface SubscriptionState {
  currentSubscription: SubscriptionInfo | null;
}

const initialState: SubscriptionState = {
  currentSubscription: null,
};

export const subscriptionSlice = createSlice({
  name: 'currentSubscription',
  initialState,
  reducers: {
    setCurrentSubscription: (state, action: PayloadAction<SubscriptionInfo | null>) => {
      state.currentSubscription = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentSubscription } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
