import { Tooltip } from '@mui/material';
import styles from '../../../../../index.module.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';

const PostingTooltip = () => {
  return (
    // <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
    <Tooltip
      placement="right"
      title="This is the posting date that affects the financial statements."
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: styles.mainGrey, // Изменение фона
            color: styles.mainWhite, // Цвет текста
            maxWidth: 'none', // Убираем ограничение по ширине
            whiteSpace: 'pre-wrap', // Разрешаем перенос текста
            fontSize: '10px' // Размер шрифта
            // padding: '0.5rem' // Отступы
          }
        },
        popper: {
          sx: {
            maxWidth: '700px' // Ограничение максимальной ширины для popper
          }
        }
      }}
    >
      <InfoOutlinedIcon sx={{ color: styles.mainRed, fontSize: '1rem', marginBottom: '0.5rem' }}/>
    </Tooltip>
    // </Box>
  );
};

export default PostingTooltip;
