import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { doLogin, LocalStorageKey } from './auth.functions';
import { MainRoute } from '../../app';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

// Создаем переменную для отслеживания статуса обновления токена
let isRefreshingToken: Promise<any> | null = null;

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

// Запрос на добавление токена к заголовкам, если он доступен
apiClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const token = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

// Функция для обновления access токена
const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await apiClient.post(`/v1/auth/refresh`, { refresh_token: refreshToken });
    return response.data;
  } catch (error) {
    isRefreshingToken = null; // Сброс статуса обновления токена
    localStorage.clear(); // Чистим хранилище
    window.location.href = MainRoute.SignIn; // Перенаправляем на главную
    throw error; // Пробрасываем ошибку дальше
  }
};

// Интерсептор для обработки ошибок ответа
apiClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as CustomAxiosRequestConfig;
    // Проверяем, не пытаемся ли мы отправить запрос на обновление токена
    const isTokenRefreshRequest = /\/v1\/auth\/refresh/.test(originalRequest.url || '');

    // Если это не запрос на обновление токена, он вернул 401 и не был повторен
    if (!isTokenRefreshRequest && error.response?.status === 401 && !originalRequest._retry) {
      if (!isRefreshingToken) {
        isRefreshingToken = refreshAccessToken().finally(() => {
          isRefreshingToken = null; // Сбрасываем статус обновления токена после завершения
        });
      }

      try {
        const newAccessToken = await isRefreshingToken;

        if (newAccessToken?.access_token) {
          doLogin(newAccessToken);
          originalRequest._retry = true; // Инициализация _retry флага
          if (!originalRequest.headers) {
            originalRequest.headers = {};
          }
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken.access_token}`;
          return apiClient(originalRequest); // Повторяем запрос с новым токеном
        }
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    // Если это был запрос на обновление и он провалился, разлогиниваем пользователя
    if (isTokenRefreshRequest && error.response?.status === 401) {
      if (isRefreshingToken) {
        isRefreshingToken = null;
        localStorage.clear();
        window.location.href = MainRoute.SignIn;
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
