import React from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LocalStorageKey } from './auth.functions';
import { errorToast } from './toast.service';
import { MainRoute } from '../../app';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const UserGuard: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuth = Boolean(localStorage.getItem(LocalStorageKey.ACCESS_TOKEN));

  if (!isAuth) {
    errorToast('You need to be logged in to access this page');
    return <Navigate to={MainRoute.SignIn}/>;
  }

  return <>{children}</>;
};

export default UserGuard;
