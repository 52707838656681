import React, { FocusEvent, useEffect, useState } from 'react';
import { FormControl, Button, Autocomplete, TextField, Typography } from '@mui/material';
import styles from '../../../index.module.scss';
import BaseModal from '../../shared/components/base.modal';
import apiClient from '../../shared/auth.interceptor';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentFirm } from '../../shared/redux/firmSlicer';
import { setCurrentFirmList } from '../../shared/redux/firmListSlicer';
import { Company } from '../clients/clients-list';
import { RootState } from '../../../store';
import TimeZoneAutocomplete, { UsTimeZone } from '../../shared/components/time-zone-autocomplete';
import { UserModel } from '../../shared/auth.functions';
import { setCurrentCompanyList } from '../../shared/redux/companySlicer';
import { errorToast } from '../../shared/toast.service';
import { useNavigate } from 'react-router-dom';

interface AddNewFirmModalProps {
  open: boolean;
  client: Company;
  handleClose: () => void;
}

interface FormErrors {
  [key: string]: string | undefined;
}

const SetupClientModal: React.FC<AddNewFirmModalProps> = ({ open, handleClose, client }) => {
  const navigate = useNavigate();
  const firmList = useSelector((state: RootState) => state.firmList.firmList);
  const currentFirm = useSelector((state: RootState) => state.currentFirm.currentFirm);
  const [errors, setErrors] = useState<FormErrors>({});
  const [touched, setTouched] = useState<{ firmName: boolean }>({
    firmName: false
  });
  const [selectedFirm, setFirm] = useState<string>('');
  const [selectedTimeZone, setSelectedTimeZone] = useState<UsTimeZone | null>(null);

  const dispatch = useDispatch();
  const validateFirm = (value: string): void => {
    let newErrors: FormErrors = { ...errors };
    if (!value) {
      newErrors.firmName = 'Please enter the code';
    }
    if (value?.length < 3) {
      newErrors.firmName = 'Firm name should be at least 3 characters';
    } else {
      delete newErrors.firmName;
    }
    setErrors(newErrors);
  };
  const resetForm = () => {
    setFirm('');
    setSelectedTimeZone(null);
    setErrors({});
    setTouched({ firmName: false });
  };
  const handleModalClose = () => {
    resetForm();
    handleClose();
  };
  // Обработчик потери фокуса для полей ввода
  const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
    // const { name } = event.target;
    setTouched({ ...touched, ['firmName']: true });
    validateFirm(event.target.value);
  };
  const handleUpdate = () => {
    const formData = {
      firmName: selectedFirm,
      timezone: selectedTimeZone?.value
    };
    apiClient.put<UserModel>(`/v1/user/clients/setup/${client.realmId}`, formData)
      .then((response) => {
        toast.success('Client setup completed');
        dispatch(setCurrentFirm(selectedFirm));
        dispatch(setCurrentFirmList(response.data.firmNames));
        dispatch(setCurrentCompanyList(response.data.companies));
        resetForm();
        handleClose();
        navigate('/user/clients');
      }).catch(() => {
      errorToast('Failed to setup Client');
      handleModalClose();
    });
  };
  useEffect(() => {
    if (currentFirm) {
      setFirm(currentFirm);
    }
  }, [currentFirm]);
  const disableSendButton = Object.keys(errors).length > 0 || selectedFirm === '' || selectedTimeZone === null;
  return (
    <BaseModal open={open} handleClose={handleModalClose}>
      <img alt={'Logo'} src="/logo120.svg"/>
      <p className="my-4">Create a new firm or select an existing one to add the client to</p>
      <Typography variant="h6" sx={{ color: styles.mainGreen, textDecoration: 'underline' }} className="my-3">
        {client?.companyName}
      </Typography>
      <FormControl fullWidth margin="normal" sx={{ border: 'none' }}>
        {
          <>
            <Autocomplete
              freeSolo
              options={firmList?.length ? firmList?.map((firm) => firm) : []}
              value={selectedFirm}
              onChange={(event, newValue) => {
                setFirm(newValue || '');
              }}
              onInputChange={(event, newInputValue) => {
                setFirm(newInputValue);
              }}
              onBlur={handleInputBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.code && touched.firmName)}
                  label="Choose firm *"
                  variant="outlined"
                />
              )}
            />{touched.firmName && errors.firmName && (
            <p style={{
              color: 'red', margin: '5px 0 0 2px', fontSize: '0.875rem', textAlign: 'left'
            }}>{errors.firmName}</p>
          )}
          </>
        }
      </FormControl>
      <FormControl fullWidth margin="normal" sx={{ border: 'none' }}>
        {
          <TimeZoneAutocomplete
            selectedTimeZone={selectedTimeZone}
            onSelectTimeZone={setSelectedTimeZone}
          />
        }
      </FormControl>
      <Button disabled={disableSendButton} onClick={handleUpdate} variant="contained"
              sx={{ mt: 2, width: '100%', bgcolor: styles.mainBlue }}>
        Create
      </Button>
    </BaseModal>
  );
};

export default SetupClientModal;
