import { Company } from './clients/clients-list';

export enum UserSubscriptions {
  Tier1 = 'tier_1',
  Tier2 = 'tier_2',
  Tier3 = 'tier_3',
}

export interface IUser {
  _id: string;
  email: string;
  name: string;
  password: string;
  role: string;
  active: boolean;
  passwordchangedAt: Date;
  passwordResetToken: string;
  passwordResetExpires: Date;
  companies: Company[];
  createdAt: Date;
  updatedAt: Date;
  isEmailVerified: boolean;
  firmNames: string[];

  //subscription
  stripeCustomerId: string;
  stripeSubscriptionId: string;
  productId: string;
  subscriptionStatus: string;
  subscriptionTier: UserSubscriptions;
  currentPeriodStart: Date;
  currentPeriodEnd: Date;
  trialEnd: Date;

  //

  clientLimit: number;
  templatePerClient: number;
}
